import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import Tooltip from '@mui/material/Tooltip';

export default function ReviewCard(props) {
  const { i18n, t } = useTranslation();
  const { data, dataIndex } = props;
  const { name, review, avatar, country, work } = data[dataIndex];

  const [value, setValue] = React.useState(4);

  // let text = review;

  return (
    <motion.div
      initial={{ y: 100, opacity: 0.5 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.4, delay: 0.2 }}
      viewport={{ once: true }}
      className='ReviewCard_Container'
    >
      <div className='ReviewCard'>
        {/* <Avatar alt='Remy Sharp' src={props.src} sx={{ width: 120, height: 120 }} /> */}
        <h2
          className='Selection'
          style={{
            marginTop: '4%',
            fontWeight: 600,
            fontSize: '1.6rem',
            fontFamily: 'Urbanist',
            opacity: '.8',
          }}
        >
          {name}
        </h2>
        <h3
          className='Selection'
          style={{
            margin: '0',
            fontWeight: 400,
            fontSize: '1.2rem',
            opacity: '.8',
            margin: '2% 0 5% 0',
          }}
        >
          {country}
        </h3>
        {/* <Rating
          name='simple-controlled'
          // name="size-medium"
          size={props.size || "medium"}
          defaultValue={2}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        /> */}
        <Tooltip title={review}>
          <p
            className='Selection'
            style={{
              width: '80%',
              fontSize: '1rem',
              opacity: '.9',
              fontWeight: '400',
            }}
          >
            {review?.length > 100 ? review?.slice(0, 100) + '...' : review}
            {/* {review} */}
          </p>
        </Tooltip>
        <h4
          className='Selection'
          style={{
            marginTop: '10%',
            fontWeight: '400',
            fontSize: '.8rem',
            fontStyle: 'italic',
            opacity: '.8',
          }}
        >
          {work}
        </h4>
      </div>
    </motion.div>
  );
}
