import { Typography } from '@mui/material';

export const data = [
  {
    title: 'Presentation of the website',
    index: 1,
    body: (
      <>
        <Typography className='LegalMentions_Data_P'>
          Under article 6 of law n ° 2004-575 of June 21, 2004 for confidence in the digital
          economy, it is specified to the users of the website
          <span className='LegalMention_FalconConsulting'> Falcon Consulting.fr </span>
          the identity of the various parties involved in carrying out and monitoring it:
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          <span className='LegalMentions_Data_H'>Owner :</span> EURL Falcon Consulting Share capital
          of € 100 VAT number: FR84813509536 - 53 avenue Victor Hugo 94290 Villeneuve Le Roi
        </Typography>
        <Typography className='LegalMentions_Data_P'>
          <span className='LegalMentions_Data_H'>Publication manager :</span> Falcon Consulting -
          contact@falconconsulting.fr <br /> The publication manager is a natural person or a legal
          person.
        </Typography>
        <Typography className='LegalMentions_Data_P'>
          <span className='LegalMentions_Data_H'>Webmaster :</span> Falcon Consulting -
          support@falconconsulting.fr
        </Typography>
        <Typography className='LegalMentions_Data_P'>
          <span className='LegalMentions_Data_H'>Host :</span> GoDaddy - 14455 N Hayden Rd Ste 226
          AZ 85260 Scottsdale United States +1 (480) 505-8800
        </Typography>
        <Typography className='LegalMentions_Data_P'>
          <span className='LegalMentions_Data_H'>Data protection officer :</span> Falcon Consulting
          - contact@falconconsulting.fr This model of legal notices is offered by the{' '}
          <span className='LegalMention_FalconConsulting'>
            {' '}
            free generator offered by Orson.io{' '}
          </span>
        </Typography>
      </>
    ),
  },
  {
    title: 'General conditions of use of the site and the services offered',
    index: 2,
    body: (
      <>
        <Typography className='LegalMentions_Data_P'>
          {' '}
          The Site constitutes a work of the mind protected by the provisions of the Intellectual
          Property Code and the applicable International Regulations. The Customer may not in any
          way reuse, assign or exploit for his own account all or part of the elements or works of
          the Site.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          Use of the site <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          implies full acceptance of the general conditions of use described below. These conditions
          of use may be modified or supplemented at any time, users of the{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span> site. are
          therefore invited to consult them on a regular basis.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          This website is normally accessible to users at all times. An interruption due to
          technical maintenance may however be decided by
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span> , which will
          then endeavor to communicate to users the dates and times of the intervention. The website{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span> is updated
          regularly by <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>{' '}
          responsible. In the same way, the legal notices can be modified at any time : they are
          nevertheless imposed on the user who is invited to refer to them as often as possible in
          order to take note of them.
        </Typography>
      </>
    ),
  },
  {
    title: 'Description of the services provided',
    index: 3,
    body: (
      <>
        <Typography className='LegalMentions_Data_P'>
          {' '}
          The purpose of the website
          <span className='LegalMention_FalconConsulting'> Falcon Consulting.fr </span>
          is to provide information concerning all of the company's activities.
          <span className='LegalMention_FalconConsulting'> Falcon Consulting.fr </span> strives to
          provide on the site
          <span className='LegalMention_FalconConsulting'> Falcon Consulting.fr </span>
          as precise information as possible. However, it can not be held responsible for omissions,
          inaccuracies and deficiencies in the update, whether by him or her makes third party
          partners who provide this information.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          All the information indicated on the site
          <span className='LegalMention_FalconConsulting'> Falcon Consulting.fr </span>
          are given for information only, and are subject to change. In addition, the information on
          the site
          <span className='LegalMention_FalconConsulting'> Falcon Consulting.fr </span>
          are not exhaustive. They are given subject to modifications having been made since they
          were put online.
        </Typography>
      </>
    ),
  },
  {
    title: 'Contractual limitations on technical data',
    index: 4,
    body: (
      <>
        <Typography className='LegalMentions_Data_P'>
          The site uses JavaScript technology. The website cannot be held responsible for material
          damage linked to the use of the site. In addition, the site user agrees to access the site
          using recent equipment, not containing any virus and with an up-to-date latest generation
          browser The site{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          is hosted by a service provider on the territory of the European Union in accordance with
          the provisions of the General Data Protection Regulation (GDPR: n ° 2016-679)
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          The objective is to provide a service that ensures the best accessibility rate. The host
          ensures the continuity of his service 24 hours a day, every day of the year. It
          nevertheless reserves the possibility of interrupting the hosting service for the shortest
          possible periods, in particular for the purposes of maintenance, improvement of its
          infrastructure, failure of its infrastructure or if the Services and Services generate
          deemed traffic. abnormal.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          and the host cannot be held responsible in the event of a malfunction of the Internet,
          telephone lines or computer and telephone equipment linked in particular to network
          congestion preventing access to the server.
        </Typography>
      </>
    ),
  },
  {
    title: 'Intellectual property and counterfeits',
    index: 5,
    body: (
      <>
        <Typography className='LegalMentions_Data_P'>
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          is the owner of the rights to intellectual property and holds the rights of use on all the
          elements accessible on the website, in particular the texts, images, graphics, logos,
          videos, icons and sounds. Any reproduction, representation, modification, publication,
          adaptation of all or part elements of the site, whatever the means or the process used, is
          prohibited without the prior written authorization of:{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          Any unauthorized use of the site or any of the elements it contains will be considered
          constituting an infringement and prosecuted in accordance with the provisions of articles
          L.335-2 and following of the Intellectual Property Code.
        </Typography>
      </>
    ),
  },
  {
    title: 'Limitations of liability',
    index: 6,
    body: (
      <>
        <Typography className='LegalMentions_Data_P'>
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          acts as the publisher of the site.{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          is responsible for the quality and veracity of the Content it publishes.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          cannot be held liable for direct and indirect damage to the user's equipment, when
          accessing the website{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>, and resulting
          either from the use of equipment that does not meet the specifications indicated in point
          4, or from the appearance of a bug or incompatibility.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          cannot also be held liable for indirect damage such as a loss of market or loss of
          opportunity following the use of the site{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>. Interactive
          spaces possibility to ask questions in the contact space are available to users.{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span> reserves the
          right to delete, without prior notice, any content posted in this space which would
          contravene to the legislation applicable in France, in particular to the provisions
          relating to data protection. If necessary,{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          also reserves the right to challenge the civil and / or criminal liability of the user, in
          particular in the event of a racist, abusive, defamatory, or pornographic message,
          regardless of the medium used text, photography, etc.
        </Typography>
      </>
    ),
  },
  {
    title: 'Personal data management',
    index: 7,
    body: (
      <>
        <Typography className='LegalMentions_Data_P'>
          The Customer is informed of the regulations concerning marketing communication, the law of
          June 21, 2014 for confidence in the Digital Economy, the Data Protection Act of 06 August
          2004 as well as the General Data Protection Regulations (RGPD: n ° 2016-679).
        </Typography>{' '}
        <br />
        <Typography className='LegalMentions_Data_P'>
          <span className='LegalMentions_Data_H'>
            7.1 Persons responsible for collecting personal data
          </span>
          <br />
          For Personal Data collected in connection with the creation of the personal account of the
          User and his navigation on the Site, the person responsible for processing Personal Data
          is: Falcon Consulting.
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          is represented by Asif Zain, its legal representative
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          As the data controller it collects,{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          undertakes to comply with the legal provisions in force. It is up to him, in particular,
          to the Customer to establish the purposes of his data processing, to provide its prospects
          and customers, from the collection of their consents, complete information on the
          processing of their personal data and to maintain a processing register in line with
          reality. Whenever{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          processes Personal Data,
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          takes all reasonable measures to ensure the accuracy and relevance of Personal Data with
          regard to the purposes for which
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          processes them.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          <span className='LegalMentions_Data_H'> 7.2 Purpose of the data collected</span> <br />
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          may process all or part of the data:
          <ul style={{ marginLeft: '2%' }}>
            <br />
            <li>
              to allow navigation on the Site and management and traceability of the services and
              services ordered by the user: connection and use data for the Site, invoicing, order
              history, etc.
            </li>
            <li>
              to prevent and fight against computer fraud (spamming, hacking, etc.): computer
              equipment used for browsing, IP address, password (hash)
            </li>
            <li>to improve navigation on the Site: connection and usage data</li>
            <li>
              to conduct optional satisfaction surveys on{' '}
              <span className='LegalMention_FalconConsulting'> Falcon Consulting </span> : email
              address
            </li>
            <li>
              to carry out communication campaigns (sms, email): telephone number, email address
            </li>
          </ul>
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          does not market your personal data which is therefore only used by necessity or for
          statistical purposes and analysis.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          <span className='LegalMentions_Data_H'>
            {' '}
            7.3 Right of access, rectification and opposition
          </span>{' '}
          <br />
          In accordance with European regulations in force, Users of{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          have the following rights:
          <ul style={{ marginLeft: '2%' }}>
            <br />
            <li>
              right of access (article 15 GDPR) and rectification (article 16 GDPR), update,
              completeness of user data right to block or erase personal user data (article 17 of
              GDPR), when they are inaccurate, incomplete, ambiguous, outdated, or the collection,
              use, communication or conservation of which is prohibited
            </li>
            <li>right to withdraw consent at any time (article 13-2c GDPR)</li>
            <li>right to limit the processing of User data (article 18 GDPR)</li>
            <li>right to oppose the processing of User data (article 21 GDPR)</li>
            <li>
              right to portability of the data that the Users will have provided, when this data is
              subject to automated processing based on their consent or on a contract (article 20
              GDPR)
            </li>
            <li>
              right to define the fate of User data after their death and to choose to whom{' '}
              <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
              must communicate (or not) their data to a third party they have previously designated
            </li>
          </ul>
          As soon as <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          becomes aware of the death of a User and in the absence of instructions from his share,{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          undertakes to destroy its data, unless their retention is necessary for purposes or to
          meet a legal obligation.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          If the User wishes to know how{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          uses his Personal Data, ask to rectify it or s opposes their processing, the User can
          contact <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          in writing at the following address: Falcon Consulting - DPO, Falcon Consulting
          <br />
          53 avenue Victor Hugo 94290 Villeneuve Le Roi.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          In this case, the User must indicate the Personal Data that he would like{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          to correct , update or delete, by identifying yourself precisely with a copy of an
          identity document (identity card or passport).
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          Requests for the deletion of Personal Data will be subject to the obligations which are
          imposed on <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          by law, in particular by matter of conservation or archiving of documents. Finally, Users
          of <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          can lodge a complaint with the supervisory authorities, and in particular the CNIL (
          https://www.cnil.fr/fr/plaintes).
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          <span className='LegalMentions_Data_H'> 7.4 Non-disclosure of personal data</span> <br />
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          refrains from processing, hosting or transferring the Information collected from its
          Customers to a country outside of it of the European Union or recognized as "unsuitable"
          by the European Commission without first informing the customer. However,{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          remains free to choose its technical and commercial subcontractors on the condition that
          it present sufficient guarantees with regard to the requirements of the General Data
          Protection Regulation (GDPR: n ° 2016-679).
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          undertakes to take all the necessary precautions to preserve the security of the
          Information and in particular that it are not communicated to unauthorized persons.
          However, if an incident affecting the integrity or confidentiality of Customer Information
          is brought to the attention of{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>, the latter
          must promptly inform the Client and communicate the corrective measures taken. In addition{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          does not collect any "sensitive data".
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          The User's Personal Data may be processed by subsidiaries of{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          and subcontractors (providers services), exclusively to achieve the purposes of this
          policy.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          Within the limits of their respective attributions and for the purposes mentioned above,
          the main persons likely to have access to the data of the Users of{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          are mainly the agents of our customer service.
        </Typography>
      </>
    ),
  },
  {
    title: 'Incident notification',
    index: 8,
    body: (
      <>
        <Typography className='LegalMentions_Data_P'>
          No matter how hard you try, no method of transmission over the Internet and no method of
          electronic storage is completely secure. We cannot therefore guarantee absolute security.
          If we become aware of a security breach, we will notify the affected users so that they
          can take appropriate action. Our incident notification procedures take into account our
          legal obligations, whether at national or European level. We are committed to fully
          informing our clients of all matters relating to account security and to providing them
          with all the information necessary to help them meet their own regulatory reporting
          obligations.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          No personal information of the site user{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          is published without the user's knowledge , exchanged, transferred, assigned or sold on
          any medium to third parties. Only the assumption of the redemption of{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          and its rights would allow the transmission of said information to the eventual purchaser
          who would in turn be held subject to the same obligation to store and modify data with
          respect to the user of the site{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          <span className='LegalMentions_Data_H'>Security </span> <br />
          To ensure the security and confidentiality of Personal Data and Personal Health Data,{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          uses protected networks by standard devices such as by firewall, pseudonymization,
          encryption and password.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          When processing Personal Data,{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          takes all reasonable measures to protect it from loss, use misappropriated, unauthorized
          access, disclosure, alteration or destruction.
        </Typography>
      </>
    ),
  },
  {
    title: "Hypertext links 'cookies' and internet tags",
    index: 9,
    body: (
      <>
        <Typography className='LegalMentions_Data_P'>
          The site <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          contains a number of hypertext links to other sites, set up with the authorization from{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>. However,{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          is unable to verify the content of the sites thus visited, and will not assume therefore
          no liability for this fact.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          Unless you decide to deactivate cookies, you accept that the site can use them. You can
          deactivate these cookies at any time, free of charge, from the deactivation possibilities
          offered to you and recalled below, knowing that this may reduce or prevent accessibility
          to all or part of the Services offered by the site.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          <span className='LegalMentions_Data_H'> 9.1. "COOKIES"</span> <br />A "cookie" is a small
          information file sent to the User's browser and saved in the User's terminal eg computer,
          smartphone, hereinafter "Cookies". This file includes information such as the domain name
          of the User, the Internet service provider of the User, the Operating system of the User,
          as well as the date and time of access. Cookies in no way risk damaging the User's
          terminal.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          may process information from the User concerning his visit to the Site, such as the pages
          viewed , the research done. This information allows{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          to improve the content of the Site and the navigation of the User.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          Cookies facilitate navigation and / or the provision of services offered by the Site, the
          User can configure his browser so that he can decide whether or not to accept them so that
          Cookies are saved in the terminal or, on the contrary, that they be rejected, either
          systematically or according to their issuer. The User can also configure his browser
          software so that the acceptance or rejection of Cookies is offered to him punctually,
          before a Cookie is likely to be saved in his terminal.{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          informs the User that, in this case, it may be that the functionality of its navigation
          software are not all available.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          If the User refuses to save Cookies in their terminal or browser, or if the User deletes
          those that are registered there, the User is informed that their browsing and experience
          on the Site may be limited. This could also be the case when{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          or one of its providers cannot recognize, by for technical compatibility, the type of
          browser used by the terminal, the language and display settings or the country from which
          the terminal appears to be connected to the Internet.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          If applicable, <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          declines all responsibility for the consequences linked to the degraded functioning of the
          Site and any services offered. by{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>, resulting from
          "i" the refusal of Cookies by the User "ii" the impossibility to{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          to save or consult the Cookies necessary for their operation due to the choice of the User
          . For the management of Cookies and the choices of the User, the configuration of each
          browser is different. It is described in the browser's help menu, which will allow you to
          know how the User can modify his wishes regarding Cookies.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          At any time, the User can choose to express and modify their wishes regarding Cookies.{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          may also call on the services of external providers to help it collect and process the
          information described in this section.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          Finally, by clicking on the icons dedicated to the social networks Twitter, Facebook,
          Linkedin and Google Plus appearing on the Site of
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          or in his mobile application and if the User has accepted the deposit of cookies by
          continuing to browse the Website or the mobile application of{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>, Twitter,
          Facebook, Linkedin and Google Plus may also place cookies on your devices "computer,
          tablet, mobile phone".
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          These types of cookies are only placed on your terminals if you agree, by continuing to
          browse the Website or the mobile application of
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>. At any time,
          the User can nevertheless withdraw his consent to{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          depositing this type of cookies .
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          <span className='LegalMentions_Data_H'> Article 9.2. INTERNET TAGS</span> <br />
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          may occasionally use Internet beacons "also called "tags", or action tags, GIF to a pixel,
          transparent GIFs, invisible GIFs and GIFs one by one" and deploy them via a specialist web
          analytics partner who may be located "and therefore store the corresponding information,
          including the IP address of User" in a foreign country.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          These tags are placed both in online advertisements allowing Internet users to access the
          Site, and on the various pages thereof.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          This technology allows{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          to assess the responses of visitors to the Site and the effectiveness of its actions "for
          example, the number of times a page is opened and the information consulted", as well as
          the use of this Site by the User.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          The external service provider may possibly collect information on visitors to the Site and
          other websites using these tags, compile reports on the activity of the Site for the
          attention of <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>,
          and provide other services relating to the use of it and the Internet.
        </Typography>
      </>
    ),
  },
  {
    title: 'Applicable law and attribution of jurisdiction',
    index: 10,
    body: (
      <>
        <Typography className='LegalMentions_Data_P'>
          Any dispute in connection with the use of the site{' '}
          <span className='LegalMention_FalconConsulting'> Falcon Consulting </span>
          is subject to French law. Apart from cases where the law does not allow it, exclusive
          jurisdiction is given to the competent courts of Créteil
        </Typography>
      </>
    ),
  },
];

export const legalMentions = [
  {
    title: 'Éditeur',
    index: 1,
    body: (
      <>
        <Typography className='LegalMentions_Data_P'>
          Le présent site web, accessible à l’adresse{' '}
          <a href='https://falconconsulting.fr' className='LegalMention_FalconConsulting'>
            {' '}
            https://falconconsulting.fr{' '}
          </a>{' '}
          est réalisé par la Société Falcon Consulting, inscrite au registre du commerce de Créteil
          sous le numéro B 813 509 536, dont le siège social est situé au 53 avenue Victor Hugo -
          94290 Villeneuve Le Roi.
          <br />
          <br />
          Le site appartient au groupe Falcon Consulting. En France, c’est la Société Falcon
          Consulting au capital de 10 000 euros, inscrite au registre du commerce de Créteil sous le
          numéro B 813 509 536 et numéro TVA intracommunautaire FR84813509536, dont le siège social
          est situé au 53 avenue Victor Hugo - 94290 Villeneuve Le Roi, qui est reconnu comme
          propriétaire.
          <br />
          <br />
          Le groupe international Falcon Consulting est un ensemble d’ESN toutes spécialisées dans
          les services informatiques, autour nouvelles technologies logicielles et digitales.
          <br />
          <br />
          Le directeur de la publication du Site Web est la société Falcon Consulting elle-même.
        </Typography>
        <br />
      </>
    ),
  },
  {
    title: 'Contenu du Site',
    index: 2,
    body: (
      <>
        <Typography className='LegalMentions_Data_P'>
          {' '}
          Le contenu du site la structure générale, les textes, les images, animées ou non, et les
          sons dont le site est composé sont la propriété exclusive de la société Falcon Consulting.
          Toute représentation totale ou partielle de ce Site et de son Contenu, par quelques
          procédés que ce soient, sans l'autorisation pré alable expresse de la société Falcon
          Consulting est interdite et constituerait une contrefaçon sanctionnée par les articles
          L.335-2 et suivants du Code de la Propriété intellectuelle.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          Le site accessible à l'adresse
          <a href='https://falconconsulting.fr' className='LegalMention_FalconConsulting'>
            {' '}
            https://falconconsulting.fr{' '}
          </a>{' '}
          est hébergé par la société GoDaddy.com, dont le siège social est situé au 14455 North
          Hayden Rd., Suite 219, Scottsdale, AZ 85260.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          Email :{' '}
          <a href='mailto:support@godaddy.com' className='LegalMention_FalconConsulting'>
            {' '}
            support@godaddy.com
          </a>
          <br />
          Téléphone : <span className='LegalMention_FalconConsulting'> + 33 9 75 18 70 39. </span>
        </Typography>
      </>
    ),
  },
  {
    title: "Licence d'utilisation du Contenu du Site",
    index: 3,
    body: (
      <>
        <Typography className='LegalMentions_Data_P'>
          {' '}
          Du seul fait de sa connexion au site, l'utilisateur reconnaît accepter de la société
          Falcon Consulting, une licence 'usage du Contenu du Site strictement limitée aux
          conditions impératives suivantes:
          <br />
          <span className='LegalMention_FalconConsulting'>
            {' '}
            La présente licence est accordée à tire non exclusif et n'est pas transmissible.{' '}
          </span>
          <br />
          <br />
          Le droit 'usage conféré à l'utilisateur / internaute est personnel et privé : c'est-à-dire
          que toute reproduction du contenu du site sur un quelconque support pour un usage
          collectif ou professionnel, même en interne dans l'entreprise, est prohibée. Il en est de
          même pour toute communication de ce contenu par voie électronique, même diffusé en
          intranet ou en extranet d'entreprise.
          <br />
          <span className='LegalMention_FalconConsulting'>
            {' '}
            Le droit 'usage comprend seulement l'autorisation de consulter le site et son contenu.{' '}
          </span>{' '}
          <br />
          <br />
          Cet usage comprend seulement l'autorisation de reproduire pour stockage aux fins de
          représentation sur écran monoposte et de reproduction en un exemplaire, pour copie de
          sauvegarde et tirage papier. Tout autre usage est soumis à l'autorisation expresse
          préalable de la société Falcon Consulting. La violation de ces dispositions soumet le
          contrevenant et toute personne responsable aux peines pénales et civiles prévues par la
          loi française.
        </Typography>
      </>
    ),
  },
  {
    title: 'Loi informatique, fichiers et libertés',
    index: 4,
    body: (
      <>
        <Typography className='LegalMentions_Data_P'>
          La société Falcon Consulting est le responsable du traitement des donees collectees sur
          son site via :
          <a href='https://falconconsulting.fr' className='LegalMention_FalconConsulting'>
            {' '}
            https://falconconsulting.fr{' '}
          </a>{' '}
          <br />
          <br />
          L'utilisateur est notamment informé que conformément à l'article 32 de la loi Informatique
          et libertés du 6 janvier 1978 modifiée, les informations qu'il communique par les
          formulaires présents sur le site de la société Falcon Consulting sot nécessaires pour
          répondre à sa demande et sont destines aux services en charge de répondre à sa demande à
          des fins de suivi de cette demande.
          <br />
          Conformément aux dispositions des articles 39 et 40 de la loi « Informatique et Libertés »
          du 6 janvier 1978 modifiée en 2004, l'utilisateur bénéficie d'un droit d'accès, de
          rectification, de mise à jour et 'effacement des informations qui le concernent, qu'il
          peut exercer en s'adressant à l'adresse mail{' '}
          <a href='mailto:contact@falconconsulting.fr' className='LegalMention_FalconConsulting'>
            {' '}
            contact@falconconsulting.fr
          </a>
          ou par courier à l'adresse de la société:
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          Falcon Consulting, 53 Avenue Victor Hugo, 94290 Villeneuve Le Roi, France, service « Droit
          des personnes » et en joignant la copie de son justificatif d'identité.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          Conformément aux dispositions de 'article 38 de la loi « Informatique et Libertés » du 6
          ianvier 1978 modifiée en 2004, l'utilisateur put également 'opposer, pour des motifs
          légitimes à ce que ses données fassent l'objet d' un traitement et sans motif et sans
          frais, à ce que ses données soient utilisées à des fins de prospection commerciale
        </Typography>
        <Typography className='LegalMentions_Data_P'>
          L'utilisateur est informé que lors de ses visites sur le site{' '}
          <a href='https://falconconsulting.fr' className='LegalMention_FalconConsulting'>
            {' '}
            https://falconconsulting.fr{' '}
          </a>{' '}
          , un cookie peut 'installer automatiquement sur son logiciel de navigation.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          Le cookie est un bloc de données qui ne permet pas d'identifier les utilisateurs, mais
          sert à enregistrer des informations relatives à la navigation de celui-ci sur le site. Le
          paramétrage du logiciel de navigation permet 'informer de la présence de cookie et
          éventuellement, de la refuser de la manière décrite à l'adresse suivante
          <a href='https://www.cnil.fr' className='LegalMention_FalconConsulting'>
            {' '}
            www.cnil.fr{' '}
          </a>{' '}
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          L'utilisateur dispose d'un droit d'accès, de retrait et de modification des données à
          caractère personnel communiques par le bias des cookies dans les conditions indiquées
          ci-dessus.
        </Typography>
        <Typography className='LegalMentions_Data_P'>
          L'utilisateur du site{' '}
          <a href='https://falconconsulting.fr' className='LegalMention_FalconConsulting'>
            {' '}
            https://falconconsulting.fr{' '}
          </a>{' '}
          est tenu de respecter les dispositions de la loi Informatique et libertés du 6 janvier
          1978 modifiée dont la violation est passible de sanctions pénales. Il doit notamment s'
          abstenir, s'agissant des informations nominatives auxquelles il accède, de toute collecte,
          de toute utilisation détournée, et d'une manière générale, de tout acte susceptible de
          porter atteinte à la vie privée ou à la réputation des personnes.
        </Typography>
      </>
    ),
  },
  {
    title: 'Liens hypertextes',
    index: 5,
    body: (
      <>
        <Typography className='LegalMentions_Data_P'>
          Les liens hypertextes mis en place dans le cadre du présent site en direction d'autres
          resources présentes sur le réseau Intemet, ne sauraient engager la responsabilité de la
          société Falcon Consulting. Les utilisateurs/internautes du site ne peuvent mettre en place
          un lien hypertexte en direction de ce site sans l'autorisation expresse et préalable de la
          société Falcon Consulting.
        </Typography>
      </>
    ),
  },
  {
    title: "Autorisation de reproduction de contenus et de mise en placed'hyperliens",
    index: 6,
    body: (
      <>
        <Typography className='LegalMentions_Data_P'>
          Les liens hypertextes mis en place dans le cadre du présent site en direction d'autres
          resources présentes sur le réseau Intemet, ne sauraient engager la responsabilité de la
          société Falcon Consulting. Les utilisateurs / internautes du site ne peuvent mettre en
          place un lien hypertexte en direction de ce site sans l'autorisation expresse et préalable
          de la société Falcon Consulting.
        </Typography>
      </>
    ),
  },
  {
    title: 'Respect de la vie privée',
    index: 7,
    body: (
      <>
        <Typography className='LegalMentions_Data_P'>
          Falcon Consulting respecte votre vie privée et s'engage à la protéger. La présente
          déclaration est destinée à vous informer de notre politique et de nos pratiques en matière
          de respect de la vie privée, ainsi que des choix que vous pouvez opérer sur la manière
          dont vos coordonnées sont recueillies en ligne et comment elles sont utilisées.
        </Typography>{' '}
        <br />
        <Typography className='LegalMentions_Data_P'>
          Le site web est régi par le Droit français. Notre activité professionnelle est répartie
          selon le pays dans lequel le contrat de prestation est signé. En cas de litige résultant
          de votre utilisation de note site web, seuls les Tribunaux français sont compétents.
        </Typography>
        <br />
        <Typography className='LegalMentions_Data_P'>
          Tous droits réservés © - {new Date().getFullYear()} Falcon Consulting
        </Typography>
      </>
    ),
  },
];
